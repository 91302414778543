import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'datasheet',
        component: () => import(/* webpackChunkName: "about" */ '../views/datasheet.vue'),
        meta: {
            title: '留资表单',

            keepAlive: true // 需要缓存页面

        },
    },

    {
        path: '/datasheet',
        name: 'datasheet',
        component: () => import(/* webpackChunkName: "about" */ '../views/datasheet.vue'),
        meta: {
            title: '留资表单',
            keepAlive: true

        },

    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        meta: {
            title: '腾势'
        },
    },

    {
        path: '/punch',
        name: 'punch',
        meta: {
            title: '展点简介'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/punch.vue')
    },

    {
        path: '/rankinglist',
        name: 'rankinglist',
        meta: {
            title: ''
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/rankingList.vue')
    },

    {
        path: '/gift',
        name: 'gift',
        meta: {
            title: '礼品兑换'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/gift.vue')
    },
    {
        path: '/photo',
        name: 'photo',
        meta: {
            title: '打卡'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/photo.vue')
    },
    {
        path: '/share',
        name: 'share',
        meta: {
            title: '分享'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/share.vue')
    },
    {
        path: '/clocksuccess',
        name: 'clocksuccess',
        meta: {
            title: '打卡成功'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/clocksuccess.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
    },
    {
        path: '/write-off',
        name: 'write-off',
        meta: {
            title: '核销'
        },
        // agreement
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/write-off.vue')
    }, {
        path: '/agreement',
        name: 'agreement',
        meta: {
            title: '隐私条例'
        },
        // agreement
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/agreement.vue')
    }, {
        path: '/answer',
        name: 'answer',
        meta: {
            title: '双枪超充答题'
        },
        // agreement
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/answer.vue')
    }
]

// photo
const router = new VueRouter({
    routes
})

export default router
