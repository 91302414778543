export const configInfo = [
    {
        id: 1,
        tltle: '声动 势界',
        introduce: '以创新科技还原声音本质<br>高保真渲染<br>炸裂低音<br>超凡空间化立体音<br>自适应声音',
    }, {
        id: 2,
        tltle: '八合一电动力总成',
        introduce: '提高空间利用率、降低成本、减轻重量',
    }, {
        id: 3,
        tltle: '双枪超充',
        introduce: '“双枪超充”是过去可兼容、未来可扩展、对用户友好、对充电商友好、不用改标准的理想解决方索。',
    }, {
        id: 4,
        tltle: 'CTB电池车身一体化技术',
        introduce: 'CTB是电池与整车架构融合设计的系统性创新技术通过将电池上盖与车身地板合二为一实现整车“三明治”结构大幅提升安全性、操控性与舒适性',
    }, {
        id: 5,
        tltle: '腾势家族成员',
        introduce: '在遵循腾势家族设计语言基础上，打破边界，将猎装、轿跑和SUV多元融合，既有SUV的全场景驾驭及视野，又有猎跑的性能和基因，打造腾势N7，猎跑SUV。',
    }, {
        id: 6,
        tltle: '云辇-A 智能空气车身控制系统 ',
        introduce: '驾享尊贵感，奢适新标杆',
    }
]

export const hotList = [
    {
        tltie: '音响',
        id: 1,
        x: 46.67,
        y: 60.43,
        w: '49px',
        h: '15px',
        backimg: require('../assets/img/音响.png')
    },
    {
        tltie: '八合一',
        id: 2,
        x: 30.93,
        y: 64.17,
        w: '80px',
        h: '16px',
        backimg: require('../assets/img/八合一.png')
    },
    {
        tltie: '双枪超充',
        id: 3,
        x: 3.2,
        y: 52.1,
        w: '49px',
        h: '15px',

        backimg: require('../assets/img/云辇.png')
    },
    {
        tltie: 'CTB',
        id: 4,
        x: 77.07,
        y: 68.18,
        w: '69px',
        h: '26px',
        backimg: require('../assets/img/CTB.png')
    },
    {
        tltie: '美好势借',
        id: 5,
        x: 70.67,
        y: 45.19,
        w: '68px',
        h: '17px',
        backimg: require('../assets/img/N7.png')
    },
    {
        tltie: '云撵',
        id: 6,
        x: 53.6,
        y: 63.77,
        w: '92px',
        h: '27px', backimg: require('../assets/img/会客厅.png')
    },
]
export const maskList = [
    {

        id: 1,
        x: 49.6,
        y: 71.12,
    },
    {

        id: 2,
        x: 43.47,
        y: 70.45,

    },
    {

        id: 3,
        x: 15.73,
        y: 59.49,

    },
    {

        id: 4,
        x: 78.93,
        y: 80.61,

    },
    {

        id: 5,
        x: 58.27,
        y: 50.67,

    },
    {

        id: 6,
        x: 44.8,
        y: 74.73,

    },

]
export const clockList = [
    {
        id: 1,
        imgSrc: require('../assets/img/yxclocksuccess.png'),
        // videoSrc: require('@/assets/img/yxclocksuccess.gif'),
    },
    {
        id: 2,
        imgSrc: require('../assets/img/bhyclocksuccess.png'),
        // videoSrc: require('@/assets/img/bhyclocksuccess.gif'),
    },
    {
        id: 6,
        imgSrc: require('../assets/img/ynclocksuccess.png'),
        // videoSrc: require('@/assets/img/ynclocksuccess.gif'),
    },
    {
        id: 4,
        imgSrc: require('../assets/img/ctbclocksuccess.png'),
        // videoSrc: require('@/assets/img/ctbclocksuccess.gif'),
    }
]