import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { NavBar, Button, Icon, Search, Cell, Popup, Circle, Calendar, Toast, Image as VanImage, Progress, Collapse, CollapseItem, List, Tabbar, TabbarItem, Picker, PullRefresh, Form, Field, CellGroup, Area } from 'vant';
import './assets/icon.css'
import Vconsole from "vconsole";
//开发环境使用，生产环境自动取消

// new Vconsole();
const isProd = process.env.NODE_ENV === 'production'
console.log(process.env.NODE_ENV)
if (!isProd) {
    new Vconsole()
}

import wx from "weixin-js-sdk";

import qs from 'qs';

Vue.prototype.qs = qs;
Vue.prototype.$wx = wx
Vue.use(NavBar).use(Button).use(Icon).use(Search).use(Cell).use(Popup).use(Circle).use(Calendar).use(Toast).use(VanImage).use(Progress).use(Collapse).use(CollapseItem).use(List).use(Tabbar).use(TabbarItem).use(Picker).use(PullRefresh).use(CellGroup).use(Field).use(Form).use(Area);

router.beforeEach((to, form, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
