import request from './requset'
import qs from 'qs'


//政务微信登录

//  const login = (data) =>{
//     return request.post('/wechatlogin',data)
// }
// 获取SDK配置
export const getJsSDKConfig = (url) =>
    request.get('api/getJsSDKConfig?url=' + url)


// 打卡
export const userClock = (data) =>
    request.post('api/userClock', data)

// 添加留资
export const addUserData = (data) =>
    request.post('api/addUserData', data)

// 查询车型库列表
export const getCar = () =>
    request.get('api/getCar')

// 查询门店列表

export const getShop = () =>
    request.get('api/getShop')
// 查询用户详情
export const getUserInfo = (uid) =>
    request.get('api/getUserInfo?uid=' + uid)

// 上传base64
// /common/uploadBASE64
export const uploadBASE64 = (imgsrc) =>
    request.post('common/uploadBASE64', imgsrc)

// 打卡点列表
// /api/getIntroductionList
export const getIntroductionList = (uid) =>
    request.get('api/getIntroductionList?uid=' + uid)

// close/denza/login
export const login = (data) =>
    request.post('denza/login', data)
// /api/verification
// 核销页面数据


export const verification = (pageNo, pageSize) =>
    request.get('api/verification?pageNo=' + pageNo + '&pageSize=' + pageSize)

// denza/verificationQRCode
export const verificationQRCode = (data) =>
    request.post('denza/verificationQRCode', data)
// 二维码核销

// 兑换礼品 api/convertGift
export const convertGift = (data) =>
    request.post('api/convertGift', data)

// 隐私条例
export const agreement = () =>
    request.get('api/agreement')

// 获取题目
export const getQuestions = (id) =>
    request.get('api/getQuestions/' + id)

// 提交题目
export const answer = (data) =>
    request.post('api/answer', data)
// /api/verification
// 核销页面数据