import axios from 'axios'
import { Toast } from 'vant';
export let netWorklinkImg = '';



//axios.defaults.baseURL = 'http://localhost:8085/'
// axios.defaults.baseURL = 'http://workapi.meswl.com/'
axios.defaults.baseURL = 'https://denzaapi.meswl.com/prod-api/'
// axios.defaults.baseURL = 'http://192.168.0.23:10099/'

/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    location.href = 'login';
}

/** 
* 请求失败后的错误统一处理 
* @param {Number} status 请求失败的状态码
*/
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        // 403 token过期
        // 清除token并跳转登录页
        case 401:
            // toLogin();
            break;
        case 403:
            // toLogin();
            break;
        // 404请求不存在
        case 404:
            Toast('请求的资源不存在');
            break;
        case 500:
            Toast('用户不存在');
            break;
        default:
            console.log(other);
    }
}

// 创建axios实例
let instance = axios.create({ timeout: 1000 * 12 });
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/** 
* 请求拦截器 
* 每次请求前，如果存在token则在请求头中携带token 
*/
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 
        if (config.url.indexOf('denza/verificationQRCode') > -1) {
            const token = localStorage.getItem('token');
            token && (config.headers['Authorization'] = 'Bearer ' + token)
        }
        // if (['get'].includes(config.method.toLowerCase())) {
        //           config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        //           config.data = qs.stringify(config.data)
        //           config.url = config.url + '?' + config.data
        //       }
        return config;
    },

    error => Promise.error(error))
// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        if (res.status === 200) {
            if (res.status === 200 && res.data.code != 200) {
                errorHandle(res.data.code, res.data.msg);
                if (res.data.msg && res.data.msg !== '4041') {
                    Toast(res.data.msg)
                }
            }
            return Promise.resolve(res)
        } else {
            return Promise.reject(res)
        }
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            console.log(response)
            // 请求已发出，但是不在2xx的范围 
            // errorHandle(response.status, response.data.msg);

            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            // store.commit('changeNetwork', false);
        }
    });

export default instance