<template>
    <div class="home" v-cloak>
        <!-- 进入提示图片 -->
        <div class="mask" v-if="isido">

            <div class="point">
                <div class="ido" @click="isido=false"></div>
            </div>
        </div>
        <div class="mask" v-if="isClock">

            <div class="Clockout">
                <div class="Clock" @click="isClock=false"></div>
            </div>
        </div>
        <!-- 打卡热点 -->
        <div v-for="(item,index) in hotList">
            <img :src="item.backimg" @click="jumpPunch(item)" class="hot-box"
                :style="`top:${item.y}%;left:${item.x}%;width:${item.w};height:${item.h}`">
            </img>
        </div>
        <!-- 打卡成功mask -->
        <div v-for="(item,index) in userClockList">
            <img :src="require('../assets/img/mask.png')" class="hot-box"
                :style="`top:${item.y}%;left:${item.x}%;width:20px;height:21px`">
            </img>
        </div>

        <!-- <div class="sancode" @click="sancode">扫码打卡</div> -->

        <tablebar :active="1"></tablebar>
    </div>
</template>

<script>

    // @ is an alias to /src

    import { Toast } from 'vant';

    import tablebar from '../components/tablebar.vue';
    import { hotList, maskList } from '../utils/configInfo.js'
    import { getJsSDKConfig, userClock, getIntroductionList } from '../api/api';
    export default {

        components: {
            tablebar
        },
        data () {
            return {
                isido: true,
                hotList,
                maskList,
                wxSdk: {},
                uid: '123',
                userClockList: [],
                isClock: false

            }
        },
        mounted () {
            this.uid = localStorage.getItem('uid')
            let first = localStorage.getItem('first')
            if (first) {
                this.isido = false

            } else {
                localStorage.setItem('first', 1)
            }
            var u = navigator.userAgent
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            let ua = navigator.userAgent.toLowerCase();
            let url
            if (/iphone|ipad|ipod/.test(ua)) {
                url = (window.location.href.split('#')[0])
            } else {
                url = (window.location.href)
            }
            // let url = location.href.split("#")[0]
            console.log(url)
            // let url = 'https://denzaapi.meswl.com'
            getJsSDKConfig(url).then(res => {
                this.wxSdk = res.data.data;
                // console.log(res)
                this.$wx.config({
                    debug: false,
                    appId: res.data.data.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.data.signature,// 必填，签名

                    jsApiList: ['scanQRCode'],
                })

                this.$wx.error(function (res) {
                    console.log('wxerr', res)
                });
                // console.log(res)
            })
            console.log(url)
            this.uid = localStorage.getItem('uid')
            this.userClockList = []
            getIntroductionList(this.uid).then(res => {
                let data = res.data.data
                // data = new Set(data)
                if (data.length > 0) {
                    data.forEach(element => {
                        this.maskList.forEach(item => {
                            if (element.id === item.id) {
                                element.x = item.x
                                element.y = item.y
                            }
                        })
                    })
                }

                this.userClockList = data
                console.log(data)
            })


        },
        beforeRouteEnter (to, from, next) {

            // console.log('要跳到的页面', to.name)
            // console.log('从那个页面跳过来', from.name)
            //  console.log(next)
            next(vm => {
                //这里把获取到的from.name给到data里面定义的sname
                if (from.name == 'datasheet') {
                    vm.isido = true
                } else {
                    vm.isido = false
                }
                console.log(vm.sname);
            });
        },
        methods: {
            jumpPunch (item) {
                // if (item.id > 4) {
                //     this.$router.push('photo?id=' + item.id)
                // } else {
                this.$router.push('punch?id=' + item.id)
                // }
                console.log(item)
            },
            sancode () {
                this.$wx.ready(() => {
                    this.$wx.scanQRCode({
                        needResult: 1,
                        scanType: ["qrCode", "barCode"], // 可以指定是原图还是压缩图，默认二者都有
                        success: (res) => {
                            var result = res.resultStr;
                            if (result.indexOf('introductionId') > -1) {

                                let introductionId = result.split('=')[1]
                                if (introductionId > 4) {
                                    this.$router.push('photo?id=' + introductionId)
                                } else {
                                    let pamas = {
                                        uid: this.uid,
                                        introductionId: introductionId,
                                    }
                                    userClock(pamas).then(res => {
                                        if (res.data.code == 200) {
                                            if (res.data.data === 0) {
                                                this.isClock = true
                                            } else {
                                                Toast('打卡成功')
                                                setTimeout(() => {
                                                    this.$router.push('clocksuccess?id=' + introductionId)
                                                }, 10)
                                                // this.$router.push('punch?id=' + introductionId)
                                            }

                                        }
                                        console.log(res)
                                    })
                                }

                            }
                            // console.log(res)
                        },
                        fail (e) {
                            console.log(e)
                        },
                    })
                })
            },
        },
    }
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }

    .home {
        width: 100%;
        height: 100vh;
        background: url('../assets/img/home-bg.png') no-repeat center center;
        background-size: 100% 100%;
        overflow: hidden;
        position: relative;
    }

    .point {
        width: 80%;
        height: 287px;
        position: absolute;
        /* ../assets/img/pop-up.png */
        background: url('../assets/img/pop-up.png') no-repeat center center;
        background-size: 100% 100%;
        margin: auto;
        top: 25%;
        left: 10%;
    }

    .Clockout {
        width: 80%;
        height: 200px;
        position: absolute;
        /* ../assets/img/pop-up.png */
        background: url('../assets/img/Clockout.png') no-repeat center center;
        background-size: 100% 100%;
        margin: auto;
        top: 25%;
        left: 10%;
    }

    .Clock {
        width: 20%;
        height: 20%;
        /* background: #000; */
        margin-left: 80%;
    }

    .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }

    .ido {
        width: 128px;
        height: 38px;
        /* background: #E3C373; */
        border-radius: 19px;
        opacity: 1;
        text-align: center;
        line-height: 38px;
        margin: 76.57% auto auto auto;
        font-size: 12px;
        /* margin-top: 80%; */
    }

    .sancode {
        width: 90%;
        height: 40px;
        background: #30343E;
        border-radius: 40px;
        opacity: 1;
        line-height: 40px;
        text-align: center;
        color: #E3C373;
        position: absolute;
        top: 82.75%;
        left: 5%;

    }

    .hot-box {
        position: absolute;
        /* width: 120px;
        height: 21px; */
        /* background: #000; */
        color: #fff;
        /* -webkit-animation-name: scaleDraw; */
        /*关键帧名称*/
        -webkit-animation-timing-function: ease-in-out;
        /*动画的速度曲线*/
        -webkit-animation-iteration-count: infinite;
        /*动画播放的次数*/
        -webkit-animation-duration: 5s;
        /*动画所花费的时间*/
    }

    @keyframes scaleDraw {

        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0% {
            transform: scale(1);
            /*开始为原始大小*/
        }

        25% {
            transform: scale(1.1);
            /*放大1.1倍*/
        }

        50% {
            transform: scale(1);
        }

        75% {
            transform: scale(1.1);
        }
    }
</style>