<template>
    <div class="">
        <van-tabbar v-model="activeTopTemp" @change="changetable" active-color="#151A20" style=" ">
            <van-tabbar-item>
                <span>车型介绍</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active2 : icon.inactive2" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item>
                <span>探索</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active1 : icon.inactive1" />
                </template>
            </van-tabbar-item>


            <van-tabbar-item>
                <span>礼品</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active : icon.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>


    export default {
        props: ['active'],
        data () {
            return {
                activeTopTemp: this.active,
                icon: {
                    active: require('../assets/img/gift.png'),
                    inactive: require('../assets/img/nogift.png'),
                    active1: require('../assets/img/find.png'),
                    inactive1: require('../assets/img/nofind.png'),
                    active2: require('../assets/img/motorcycle.png'),
                    inactive2: require('../assets/img/nomotorcycle.png'),
                },
            }

        },


        methods: {
            changetable () {

                if (this.activeTopTemp == 0) {
                    this.$wx.miniProgram.navigateTo({ url: '/page/car/pages/carLibrary/index/index' })
                } else if (this.activeTopTemp == 1) {
                    this.$router.replace('/home')
                } else if (this.activeTopTemp == 2) {
                    this.$router.replace('/gift')

                }
                console.log(this.activeTopTemp)
            }
        },
        mounted () {
            // var u = navigator.userAgent
            // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            // let ua = navigator.userAgent.toLowerCase();
            // let url
            // if (/iphone|ipad|ipod/.test(ua)) {
            //     url = (window.location.href.split('#')[0])
            // } else {
            //     url = (window.location.href)
            // }
            // // let url = location.href.split("#")[0]
            // console.log(url)
            // // let url = 'https://denzaapi.meswl.com'
            // getJsSDKConfig(url).then(res => {
            //     this.wxSdk = res.data.data;
            //     // console.log(res)
            //     this.$wx.config({
            //         debug: false,
            //         appId: res.data.data.appId, // 必填，公众号的唯一标识
            //         timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            //         nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            //         signature: res.data.data.signature,// 必填，签名

            //         jsApiList: ['scanQRCode'],
            //     })

            //     this.$wx.error(function (res) {
            //         console.log('wxerr', res)
            //     });
            //     // console.log(res)
            // })
        },
    }
</script>



<style scoped less>

</style>